var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"data-table",class:[
    _vm.itemsLoading ? 'data-table--loading' : '',
    _vm.canBeSelected ? 'data-table--selectable' : '' ],attrs:{"elevation":"4"}},[_c('v-data-table',{staticClass:"elevation-0 custom-data-table",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.searchTerm,"sort-by":_vm.sortBy.value,"sort-desc":_vm.sortDesc,"hide-default-footer":"","hide-default-header":"","item-class":_vm.itemClass},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([(_vm.itemsLoading)?{key:"body",fn:function(){return [(_vm.$vuetify.breakpoint.lgAndDown)?_vm._l((5),function(item){return _c('logs-table-item-skeleton-mobile',{key:item})}):[_c('logs-table-sort-skeleton'),_c('logs-table-skeleton-item')]]},proxy:true}:null,{key:"no-results",fn:function(){return [(_vm.searchTerm)?_c('DataIteratorNoResultsContainer',{attrs:{"searchTerm":_vm.searchTerm},on:{"clearAllFilters":function($event){return _vm.$emit('clearFilters')}}}):_vm._e()]},proxy:true},{key:"no-data",fn:function(){return [_vm._t("no-data")]},proxy:true},(!_vm.$vuetify.breakpoint.mobile && _vm.items.length)?{key:"header",fn:function(ref){
  var isMobile = ref.isMobile;
  var props = ref.props;
  var on = ref.on;
return [(!_vm.itemsLoading)?_c('table-custom-sort',_vm._g({attrs:{"isMobile":isMobile,"props":props,"v-on":on}},_vm.$listeners)):_vm._e()]}}:null,{key:"item.databases",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._t("databases",null,null,item)],2)]}},{key:"item.updated_at",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._t("updated_at",null,null,item)],2)]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex actions-row"},[_vm._t("actions",null,null,item)],2)]}},(_vm.$vuetify.breakpoint.mobile)?{key:"item",fn:function(ref){
  var item = ref.item;
  var headers = ref.headers;
return [_c('tr',[_c('td',{staticClass:"w-100"},[_c('div',{staticClass:"mobile-table-item"},[_c('div',{staticClass:"mobile-table-item__values"},[_vm._t("mobile",null,null,{ item: item, headers: headers }),_c('div',{staticClass:"mobile-table-item__row"},[_c('div',{staticClass:"mobile-table-item__header",on:{"click":function($event){return _vm.$emit('update:sortBy', headers[1])}}},[_vm._v(" "+_vm._s(_vm.$t('table.header.actions'))+" ")]),_c('div',{staticClass:"mobile-table-item__value"},[_vm._t("actions")],2)])],2)])])])]}}:null,(_vm.items.length)?{key:"footer",fn:function(ref){
  var props = ref.props;
return [_c('div',[_c('data-iterator-footer',_vm._g({attrs:{"page":props.pagination.page,"numberOfPages":props.pagination.pageCount,"possibleItemsPerPage":_vm.possibleItemsPerPage,"listStyle":_vm.listStyle,"itemsPerPage":props.pagination.itemsPerPage}},_vm.$listeners))],1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }